import React from "react";
import { Typography, Box } from "@mui/material";
import Grid from "@mui/material/Grid2";
import aboutimg from "./../Assets/images/Hospital_Image.jpg";
import "./../Assets/css/pagescss/Aboutpage.css";

function Aboutpage() {
  return (
    <>
      <div className="Homepage">
        <div className="Homebanner-container">
          <div className="Aboutbanner"></div>
          <div className="border"></div>
        </div>
      </div>
      <div className="about-section">
        <Grid container rowSpacing={1} padding={"40px"}>
          <Grid size={6}>
            <img src={aboutimg} alt="aboutimg" width={"650px"} />
          </Grid>
          <Grid size={6}>
            <Box
              sx={{
                backgroundColor: "#171859",
                padding: "20px",
                width: "fit-content",
                borderRadius: "10px 10px 0px 0px",
              }}
            >
              <Typography
                id="text-20-600-15-Poppins"
                textAlign={"justify"}
                color="#FFFFFF"
              >
                WELCOME TO <br /> MedFORT HOSPITAL
              </Typography>
            </Box>
              
            <Typography
              id="text-15-Poppins"
              textAlign={"justify"}
              mt={4}
              sx={{ color: "#171859" }}
            >
              <b> MedFort Hospital and CliniX </b>will be a leading
              healthcare provider in Qatar committed to delivering exceptional
              medical care and treatment at competitive prices. Our modern
              facilities, experienced medical professionals, and steadfast
              dedication to innovation reflect our mission to transform
              healthcare services and enhance the well-being of our community.
            </Typography>
          </Grid>
        </Grid>
      </div>
    </>
  );
}

export default Aboutpage;
