import React, { useEffect, useState } from "react";
import {
  Typography,
  Box,
  Grid,
  CircularProgress,
  Container,
} from "@mui/material";
import Ologo from "./../Assets/images/O_logo.jpg";
import BannerImage from "./../Assets/images/Banner-images/Departments.jpg";

function Departmentspage() {
  // State to store department data
  // const [departments, setDepartments] = useState([]);
  const [loading, setLoading] = useState(false); // State to handle loading state

  // Department Data Array
  const departments = [
    {
      name: "Cardiology",
      image: require("./../Assets/images/Departments/Cardiology.png"),
      description:
        "The cardiology department specializes in diagnosing, treating, and preventing heart-related diseases and conditions. It offers various services, including cardiac evaluations and treatment of coronary artery disease, arrhythmias, heart failure, and hypertension. Our department is equipped with state-of-the-art diagnostic tools like ECGs, echocardiograms, and stress tests and provides advanced treatments such as angioplasty, stent placement, and heart surgery. The team comprises highly trained cardiologists, cardiac surgeons, and specialized nursing staff working together to offer comprehensive, patient-centered care.",
    },
    {
      name: "Urology",
      image: require("./../Assets/images/Departments/Urology.png"),
      description:
        "The Urology Department specializes in diagnosing and treating conditions related to the urinary tract and male reproductive system. It offers comprehensive services for both men and women, addressing issues such as urinary incontinence, kidney stones, urinary tract infections, and prostate problems. The department has advanced diagnostic and surgical technologies, including minimally invasive procedures like laparoscopy and laser treatments. Specialists in the department collaborate with other medical experts to provide holistic care, focusing on patient comfort and successful long-term outcomes.",
    },
    {
      name: "Pulmonology",
      image: require("./../Assets/images/Departments/Pulmonology.png"),
      description:
        "The pulmonology department specializes in diagnosing and treating diseases related to the respiratory system, including the lungs and airways. It offers care for conditions such as asthma, chronic obstructive pulmonary disease (COPD), pneumonia, lung cancer, sleep apnea, and pulmonary hypertension. Services typically include diagnostic procedures like spirometry, bronchoscopy, and imaging, as well as advanced therapies such as pulmonary rehabilitation, oxygen therapy, and ventilatory support. The department works closely with critical care units for managing severe respiratory illnesses and often collaborates with other specialties for comprehensive patient care.",
    },
    {
      name: "ENT & Audiology",
      image: require("./../Assets/images/Departments/ENT-&-Audiology.png"),
      description:
        "Our ENT and Audiology Department specializes in comprehensive care for ear, nose, throat, and hearing disorders. Our team of experienced ENT surgeons and audiologists provide advanced diagnostic services, surgical treatments, and hearing solutions for patients of all ages. From treating common conditions like sinusitis and ear infections to offering specialized services such as cochlear implants and hearing aids, we are committed to delivering personalized care with the latest medical technologies.",
    },
    {
      name: "Obstetrics & Gynecology",
      image: require("./../Assets/images/Departments/Obstetrics-&-Gynecology.png"),
      description:
        "The Obstetrics and gynecology department provides comprehensive care for women at all stages of life, from routine gynecological check-ups to advanced obstetric care. Our team is equipped to manage routine and high-risk pregnancies, fertility issues, and gynecological conditions. The department focuses on personalized care, offering the latest diagnostic and treatment technologies to ensure patient comfort and safety.",
    },
    {
      name: "Pediatric",
      image: require("./../Assets/images/Departments/Pediatric.png"),
      description:
        "The Pediatric Department offers comprehensive care for infants, children, and adolescents. Our expert team of pediatricians provides a wide range of services, including preventive care, vaccinations, routine check-ups, and treatment for acute and chronic illnesses. With a child-friendly environment and state-of-the-art medical facilities, we are dedicated to ensuring the health and well-being of young patients through personalized, compassionate care.",
    },
    {
      name: "Internal Medicine",
      image: require("./../Assets/images/Departments/Internal-Medicine.png"),
      description:
        "The Internal Medicine department provides comprehensive care for adults, focusing on the prevention, diagnosis, and treatment of a wide range of illnesses and chronic conditions. Our team of skilled internists offers personalized care, emphasizing patient education and wellness. We utilize advanced diagnostic tools and evidence-based practices to ensure optimal health outcomes for our patients.",
    },
    {
      name: "General Surgery",
      image: require("./../Assets/images/Departments/General-Medicine.png"),
      description:
        "The General Surgery department provides comprehensive surgical care, specialising in a wide range of procedures, including abdominal, hernia, and gastrointestinal surgeries. Our experienced surgeons use advanced techniques and cutting-edge technology to ensure optimal patient outcomes. We prioritise patient safety, offering personalised treatment plans and minimally invasive options to promote faster recovery.",
    },
    {
      name: "Neurology",
      image: require("./../Assets/images/Departments/Neurology.png"),
      description:
        "Our Neurology department specialises in the diagnosis and treatment of a wide range of neurological disorders, including stroke, epilepsy, multiple sclerosis, and headaches. Equipped with advanced diagnostic tools and staffed by highly skilled neurologists, we offer comprehensive care for patients, including personalised treatment plans and rehabilitation services to support long-term neurological health.",
    },
    {
      name: "Oncology",
      image: require("./../Assets/images/Departments/Oncology.png"),
      description:
        "The Oncology Department offers comprehensive cancer care, combining advanced diagnostics, personalised treatment plans, and multidisciplinary support. Equipped with cutting-edge technology and a team of expert oncologists, the department focuses on delivering compassionate care with a patient-centred approach. It provides treatments such as chemotherapy, radiation therapy, and immunotherapy.",
    },
    {
      name: "Dermatology",
      image: require("./../Assets/images/Departments/Dermatology.png"),
      description:
        "Our Dermatology department offers comprehensive skin care services, specializing in the diagnosis and treatment of a wide range of skin, hair, and nail conditions. With a team of expert dermatologists and advanced technology, we provide personalized solutions for medical, cosmetic, and surgical dermatology, ensuring the highest level of patient care.",
    },
    {
      name: "Ophthalmology",
      image: require("./../Assets/images/Departments/Ophthalmology.png"),
      description:
        "The Ophthalmology department offers comprehensive eye care services, including routine eye exams, advanced diagnostics, and surgical treatments. With state-of-the-art technology and a team of experienced ophthalmologists, the department specializes in cataract surgery, glaucoma management, and cures for retinal diseases. The focus is on personalized patient care to ensure optimal vision, health, and outcomes.",
    },
  ];

  return (
    <>
      <Box
        sx={{
          backgroundImage: `url(${BannerImage})`,
          backgroundSize: "contain",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
          height: { xs: "100px", sm: "250px", md: "300px" },
          width: "100%",
        }}
      >
        <Box sx={{ height: "8px", backgroundColor: "#B07E2C" }} />
      </Box>
      <Container>
        <Grid container justifyContent={"center"} mt={8}>
          <Grid
            display={"flex"}
            alignItems={"center"}
            sx={{ color: "#171859" }}
          >
            <img src={Ologo} alt="o-logo" width={"40px"} height={"40px"} />
            <Typography id="text-30-600-15-Poppins">UR </Typography>
            <Typography id="text-30-600-15-Poppins" color="#B07E2C" ml={1}>
              DEPARTMENTS{" "}
            </Typography>
          </Grid>
        </Grid>

        {/* Loading Spinner while fetching */}
        {loading ? (
          <Grid container justifyContent="center" mt={5}>
            <CircularProgress />
          </Grid>
        ) : (
          // Departments Grid with responsive breakpoints
          <Grid container spacing={4} sx={{ color: "#171859", mt: 3 }}>
            {departments.map((department, index) => (
              <Grid item xs={12} sm={6} md={4} key={index}>
                <Box
                  display={"flex"}
                  flexDirection={"column"}
                  alignItems={"center"}
                  justifyContent={"center"}
                  p={2}
                >
                  <img
                    src={department.image} // Adjusted path to serve from uploads
                    alt={department.name}
                    style={{ width: "50px", height: "50px" }}
                  />
                  <Typography
                    id="text-20-600-15-Poppins"
                    textAlign={"center"}
                    mt={2}
                  >
                    {department.name}
                  </Typography>
                  <Typography
                    id="text-15-Poppins"
                    textAlign={"justify"}
                    mt={2}
                    sx={{ maxHeight: "100%" }}
                  >
                    {department.description}
                  </Typography>
                </Box>
              </Grid>
            ))}
          </Grid>
        )}
      </Container>
    </>
  );
}

export default Departmentspage;
