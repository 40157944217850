import './App.css';
import { BrowserRouter as Router } from 'react-router-dom';
import { LoadingProvider } from './Common/Loader';
import Navbar1 from './Common/Navbar1';
import NavbarMain from './Common/NavbarMain';
import AppRoutes from './Routes/Allroutes';

function App() {
  return (
    <LoadingProvider>
      <div className="App">
        <Router>
          {/* <Navbar1 /> */}
          <NavbarMain />
          <AppRoutes/>
        </Router>
      </div>
    </LoadingProvider>
  );
}

export default App;
