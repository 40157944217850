import React from "react";
import { Box, Typography } from "@mui/material";
import Grid from "@mui/material/Grid";
import Ologo from "./../Assets/images/O_logo.jpg";
import Radiology from "./../Assets/images/Radiology.jpg";
import Laboratory from "./../Assets/images/Laboratory.jpg";
import Evidence from "./../Assets/images/Our-sevices/Evidence based practices.jpg";
import Expert from "./../Assets/images/Our-sevices/Expert Medical Team.jpg";
import Personalized from "./../Assets/images/Our-sevices/Persolized Care.jpg";
import Leading from "./../Assets/images/Our-sevices/Leading the way in Healthcare.jpg";
import Exceptional from "./../Assets/images/Our-sevices/Exceptional Patient Experience.jpg";
import Multidisciplinary from "./../Assets/images/Our-sevices/Multidisciplinary Approach.jpg";
import Accessible from "./../Assets/images/Our-sevices/Accessible to all.jpg";
import Transparent from "./../Assets/images/Our-sevices/Transparnet pricing.jpg";
import Community from "./../Assets/images/Our-sevices/Community Focused.jpg";
import "./../Assets/css/pagescss/Facilitiespage.css";
import BannerImage from "./../Assets/images/Banner-images/Our-Service.jpg";

function Facilitiespage() {
  const facilitiesData = [
    {
      title: "Advanced Medical Technology",
      imageSrc: Radiology,
      description:
        "At MedFort Hospital and CliniX, we utilize the latest advancements in medical technology to provide our patients with the most effective and cutting-edge treatments. Our state-of-the-art facilities and equipment ensure optimal outcomes and patient comfort.",
    },
    {
      title: "Comprehensive Care",
      imageSrc: Laboratory,
      description:
        "We offer a full spectrum of healthcare services, from preventative care to specialized treatments. Our team of experienced and dedicated medical professionals work collaboratively to deliver personalized care tailored to each patient's unique needs.",
    },
    {
      title: "Evidence-Based Practices",
      imageSrc: Evidence,
      description:
        "Our treatments are grounded in the latest scientific research and medical best practices. We constantly strive to stay at the forefront of healthcare, ensuring our patients receive the most effective and innovative care available.",
    },
    {
      title: "Expert Medical Team",
      imageSrc: Expert,
      description:
        "Our team of highly skilled and experienced medical professionals covers a wide range of specialities, ensuring that we can provide comprehensive, holistic care to our patients. We are dedicated to delivering the best possible outcomes for every individual who walks through our doors.",
    },
    {
      title: "Personalized Care",
      imageSrc: Personalized,
      description:
        "At MedFort, we understand that every patient is unique. That's why we tailor our approach to each individual, addressing their specific needs and concerns with empathy and professionalism. Our goal is to make your healthcare experience as comfortable and effective as possible.",
    },
    {
      title: "Leading the Way in Healthcare",
      imageSrc: Leading,
      description:
        "At MedFort Hospital and CliniX is set to become the first modern speciality clinic and hospital in Qatar, offering the latest and most advanced medical treatments and care in a state-of-the-art facility. With its cutting-edge design and innovative approach to healthcare, MedFort is poised to redefine the patient experience and raise the standard of medical services in the region.",
    },
    {
      title: "Exceptional Patient Experience",
      imageSrc: Exceptional,
      description:
        "At the heart of MedFort's mission is a commitment to delivering an exceptional patient experience. From the moment you walk through the doors, you'll be greeted by a warm and inviting atmosphere, designed to put you at ease and make your healthcare journey as comfortable and stress-free as possible.",
    },
    {
      title: "Multidisciplinary Approach",
      imageSrc: Multidisciplinary,
      description:
        "MedFort Hospital and CliniX brings together a comprehensive team of specialists across all medical disciplines, ensuring that you receive the most comprehensive and holistic care possible. This integrated approach allows our experts to collaborate and develop tailored treatment plans that address your unique healthcare needs.",
    },
    {
      title: "Accessible to All",
      imageSrc: Accessible,
      description:
        "At MedFort Hospital and CliniX, we believe that highquality healthcare should be available and accessible to everyone in the community, regardless of their financial situation. We are dedicated to providing affordable, costeffective services to ensure that no one is left behind.",
    },
    {
      title: "Transparent Pricing",
      imageSrc: Transparent,
      description:
        "We are committed to being transparent about our pricing and costs, so that our patients can make informed decisions about their care. We strive to offer fair and competitive rates that are clearly communicated, allowing our community to plan and budget for their healthcare needs.",
    },
    {
      title: "Community-Focused",
      imageSrc: Community,
      description:
        "Our mission is not just to provide excellent medical care, but to be a true partner and advocate for the wellbeing of the community we serve. We work closely with local organizations and leaders to identify and address the unique healthcare needs of the people of Qatar.",
    },
  ];

  return (
    <>
      <Box
        sx={{
          backgroundImage: `url(${BannerImage})`,
          backgroundSize: "contain",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
          height: { xs: "100px", sm: "250px", md: "300px" },
          width: "100%",
        }}
      >
        <Box sx={{ height: "8px", backgroundColor: "#B07E2C" }} />
      </Box>

      <div className="facilites-section">
        <Grid container display={"flex"} justifyContent={"center"} mt={8}>
          <Grid
            display={"flex"}
            alignItems={"center"}
            sx={{ color: "#171859" }}
          >
            <img src={Ologo} alt="o-logo" width={"40px"} height={"40px"} />
            <Typography
              id="poppins"
              sx={{
                fontSize: { xs: "1.2rem", sm: "1.5rem", md: "1.7rem" }, // Responsive font size
                fontWeight: 600,
              }}
            >
              UR
            </Typography>
            <Typography
              id="poppins"
              color="#B07E2C"
              sx={{
                fontSize: { xs: "1.2rem", sm: "1.5rem", md: "2rem" }, // Responsive font size
                fontWeight: 600,
                marginLeft: { xs: "8px", sm: "8px" }, // Add spacing between the two texts on larger screens
                marginTop: { xs: "0px", sm: "0" }, // Add margin on mobile to create better spacing
              }}
            >
              SERVICE HIGHLIGHTS
            </Typography>
          </Grid>
        </Grid>
      </div>

      <Grid container spacing={2} mt={1} sx={{ padding: "20px" }}>
        {facilitiesData.map((facility, index) => (
          <Grid item xs={12} md={12} lg={6} xl={6} key={index}>
            <Box
              sx={{
                backgroundColor: "#171859",
                padding: "20px",
                width: "fit-content",
                borderRadius: "10px 10px 0px 0px",
              }}
            >
              <Typography
                id="text-18-500-15-Poppins"
                textAlign={"justify"}
                color="#FFFFFF"
              >
                {facility.title}
              </Typography>
            </Box>
            <Box
              display="flex"
              mt={1}
              flexDirection={{ xs: "column", sm: "row" }}
            >
              <Box
                component="img"
                src={facility.imageSrc}
                alt={facility.title}
                sx={{
                  width: "100%",
                  maxWidth: "300px",
                  height: "200px",
                  objectFit: "cover",
                  marginRight: { xs: 0, sm: "10px" },
                  mb: { xs: 2, sm: 0 },
                }}
              />
              <Typography
                id="text-15-Poppins"
                textAlign={"justify"}
                padding={"15px"}
              >
                {facility.description}
              </Typography>
            </Box>
          </Grid>
        ))}
      </Grid>
    </>
  );
}
export default Facilitiespage;
