import React, { useState } from "react";
import { Typography, Box, Button, Container } from "@mui/material";
import Grid from "@mui/material/Grid2";
import { useNavigate } from "react-router-dom";
import Ologo from "./../Assets/images/O_logo.jpg";
import doctor1 from "./../Assets/images/Doctor1_with-background-0000.jpg";
import doctor2 from "./../Assets/images/Doctor2_with-background-0000.jpg";
import doctor3 from "./../Assets/images/Doctor3_with-background-0000.jpg";
import AddIcon from "@mui/icons-material/Add";
import LoginModal from "./../Common/Loginmodal";
import "./../Assets/css/pagescss/Doctorspage.css";
import BannerImage from "./../Assets/images/Banner-images/Doctors.jpg";

const doctorsData = [
  {
    id: 1,
    name: "Dr. Johnes",
    specialization: "General Medicine",
    image: doctor1,
  },
  {
    id: 2,
    name: "Dr. Smitha",
    specialization: "OB & GYNECOLOGY",
    image: doctor2,
  },
  {
    id: 3,
    name: "Dr. Rachel",
    specialization: "DERMATOLOGY",
    image: doctor3,
  },
];

const Doctorspage = () => {
  const navigate = useNavigate();
  const [loginModalOpen, setLoginModalOpen] = useState(false);
  const user = JSON.parse(localStorage.getItem("user"));

  const handleBookAppointment = (doctor) => {
    if (!user) {
      setLoginModalOpen(true);
    } else {
      navigate("/Appoinmentform", { state: { doctor } });
    }
  };

  return (
    <>
      <Box
        sx={{
          backgroundImage: `url(${BannerImage})`,
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
          height: { xs: "100px", sm: "250px", md: "300px" },
          width: "100%",
        }}
      >
        <Box sx={{ height: "8px", backgroundColor: "#B07E2C" }} />
      </Box>

      <Container maxWidth="lg" sx={{ mt: 4 }}>
        <Grid container justifyContent="center" mb={4}>
          <Grid item display="flex" alignItems="center" sx={{ color: "#171859" }}>
            <img src={Ologo} alt="o-logo" width="40px" height="40px" />
            <Typography
              variant="h4"
              sx={{
                fontWeight: 600,
                fontFamily: "Poppins, sans-serif",
                ml: 1,
              }}
            >
              UR
            </Typography>
            <Typography
              variant="h4"
              sx={{
                fontWeight: 600,
                fontFamily: "Poppins, sans-serif",
                color: "#B07E2C",
                ml: 1,
              }}
            >
              DOCTORS
            </Typography>
          </Grid>
        </Grid>

        <Grid container spacing={4}>
          {doctorsData.map((doctor) => (
            <Grid
              item
              xs={12}
              sm={6}
              md={4}
              key={doctor.id}
              display="flex"
              flexDirection="column"
              alignItems="center"
            >
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  width: "100%",
                }}
              >
                <img
                  src={doctor.image}
                  alt={doctor.name}
                  style={{ width: "100%", maxWidth: "300px", borderRadius: "8px" }}
                />
              </Box>
              <Typography
                variant="h6"
                textAlign="center"
                sx={{ fontWeight: 500, mt: 2, fontFamily: "Poppins, sans-serif" }}
              >
                {doctor.name}
              </Typography>
              <Typography
                variant="subtitle1"
                textAlign="center"
                sx={{ color: "#B07E2C", fontFamily: "Poppins, sans-serif" }}
              >
                {doctor.specialization}
              </Typography>
              <Box display="flex" justifyContent="center" mt={2}>
                <Button
                  variant="contained"
                  onClick={() => handleBookAppointment(doctor)}
                  sx={{
                    borderRadius: "40px",
                    textTransform: "capitalize",
                    fontSize: { xs: "14px", sm: "16px" },
                    padding: { xs: "10px 20px", sm: "15px 40px" },
                    backgroundColor: "#B07E2C",
                    color: "#fff",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    position: "relative",
                    transition:
                      "transform 0.3s ease, background-color 0.3s ease",
                    "&:hover": {
                      backgroundColor: "#171859",
                      transform: "scale(1.05)",
                    },
                  }}
                >
                  <AddIcon
                    sx={{
                      position: "absolute",
                      left: "10px",
                      fontSize: "20px",
                    }}
                  />
                  Book Now
                </Button>
              </Box>
            </Grid>
          ))}
        </Grid>
      </Container>

      <LoginModal open={loginModalOpen} handleClose={() => setLoginModalOpen(false)} />
    </>
  );
};

export default Doctorspage;
