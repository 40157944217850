import React from "react";
import Logo from "../Assets/images/Logo.jpg";

const ComingSoon = () => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        minHeight: "100vh",
        textAlign: "center",
        padding: "20px",
      }}
      className="coming-soon-container"
    >
      <img
        src={Logo}
        alt="Logo"
        style={{
          width: "100%",
          height: "auto",
          marginBottom: "20px",
        }}
        className="coming-soon-logo"
      />
      <h1
        style={{
          fontSize: "clamp(1.5rem, 5vw, 2.5rem)",
          color: "#B07E2C",
          fontFamily: "Poppins, sans-serif",
          fontWeight: 600,
        }}
        className="coming-soon-text"
      >
        Coming Soon.....!
      </h1>
    </div>
  );
};

export default ComingSoon;
