import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Container from "@mui/material/Container";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import logo from "../Assets/images/Logo.jpg";
import { NavLink } from "react-router-dom";
import MenuIcon from "@mui/icons-material/Menu";
import { Drawer, IconButton } from "@mui/material";

const pages = [
  { name: "Departments", path: "/Departmentspage" },
  { name: "Doctors", path: "/Doctorspage", disabled: true },
  { name: "Our Service Highlights", path: "/Facilitiespage" },
  { name: "Packages", path: "/Comingsoon" },
  { name: "Testimonials", path: "/Comingsoon" },
  { name: "Insurance", path: "/Comingsoon" },
  { name: "News & Events", path: "/Comingsoon" },
  { name: "Gallery", path: "/Comingsoon" },
  { name: "Contact", path: "/Comingsoon" },
];

function NavbarMain() {
  const [activePage, setActivePage] = React.useState(pages[0].name); // Default to the first page
  const [openDrawer, setOpenDrawer] = React.useState(false);

  const toggleDrawer = () => {
    setOpenDrawer(!openDrawer);
  };

  const handlePageClick = (pageName) => {
    setActivePage(pageName);
  };

  return (
    <AppBar position="static" sx={{ backgroundColor: "#FFFFFF" }}>
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          <Grid container alignItems="center" justifyContent="space-between">
            {/* Logo Section */}
            <Grid item xs={6} sm={2} md={2.5}>
              <Box
                display="flex"
                justifyContent={{ xs: "flex-start", sm: "flex-start" }}
              >
                {/* Make the logo clickable and navigate to /homepage */}
                <NavLink to="/homepage">
                  <img src={logo} alt="logo" width={"200px"} />
                </NavLink>
              </Box>
            </Grid>

            {/* Navbar Links */}
            <Grid item xs={6} sm={10} md={9.5}>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: { xs: "flex-end", md: "center" },
                  alignItems: "center",
                  flexWrap: "wrap",
                }}
              >
                {/* Links for Larger Screens */}
                <Box
                  sx={{
                    display: { xs: "none", md: "flex" },
                    gap: 2,
                  }}
                >
                  {pages.map((page) => (
                    <Button
                      key={page.name}
                      id="poppins"
                      sx={{
                        color: activePage === page.name ? "#B07E2C" : "#171859", // Active page styling
                        fontSize: {
                          xs: "14px",
                          sm: "16px",
                          md: "8px",
                          lg: "13px",
                        },
                        fontWeight: 600,
                        pointerEvents: page.disabled ? "none" : "auto",
                        opacity: page.disabled ? 0.5 : 1,
                      }}
                      onClick={() => !page.disabled && handlePageClick(page.name)}
                    >
                      <NavLink
                        to={page.path}
                        style={{
                          textDecoration: "none",
                          color: "inherit",
                        }}
                      >
                        {page.name}
                      </NavLink>
                    </Button>
                  ))}
                </Box>

                {/* Hamburger Icon for Mobile */}
                <IconButton
                  sx={{
                    display: { xs: "block", md: "none" }, // Show only for small screens
                  }}
                  onClick={toggleDrawer}
                >
                  <MenuIcon sx={{ color: "#171859" }} />
                </IconButton>
              </Box>
            </Grid>
          </Grid>
        </Toolbar>
      </Container>

      {/* Drawer for Mobile Screens */}
      <Drawer
        anchor="right"
        open={openDrawer}
        onClose={toggleDrawer}
        sx={{
          display: { xs: "block", md: "none" },
        }}
      >
        <Box
          sx={{
            width: 250,
            padding: 2,
            display: "flex",
            alignItems: 'flex-end',
            flexDirection: "column",
            gap: 3,
          }}
          role="presentation"
          onClick={toggleDrawer}
          onKeyDown={toggleDrawer}
        >
          {pages.map((page) => (
            <Button
              id="text-13-600-15-Poppins"
              key={page.name}
              sx={{
                color: activePage === page.name ? "#B07E2C" : "#171859",
                pointerEvents: page.disabled ? "none" : "auto",
                opacity: page.disabled ? 0.5 : 1,
              }}
              onClick={() => !page.disabled && handlePageClick(page.name)}
            >
              <NavLink
                to={page.path}
                style={{
                  textDecoration: "none",
                  color: "inherit",
                }}
              >
                {page.name}
              </NavLink>
            </Button>
          ))}
        </Box>
      </Drawer>
    </AppBar>
  );
}

export default NavbarMain;
