import React from "react";
import { Box, TextField, Typography, Container } from "@mui/material";
import Grid from "@mui/material/Grid";
import Ologo from "./../Assets/images/O_logo.jpg";
import cardio from "./../Assets/images/Cardiology_Icon.jpg";
import urology from "./../Assets/images/Urology-Icon.jpg";
import generalmedi from "./../Assets/images/General-Medicine_Icon.jpg";
import BannerImage from './../Assets/images/Banner-images/Home.jpg';
import AppointmentImage from './../Assets/images/Banner-images/Appointment.jpg';

function Homepage() {
  const Department = [
    { label: "-- SELECT DEPARTMENT --" },
    { label: "INTERNAL MEDICINE" },
    { label: "GENERAL MEDICINE" },
    { label: "GYNAECOLOGY" },
    { label: "PAEDIATRICS" },
    { label: "OPHTHALMOLOGY" },
    { label: "ENT" },
    { label: "ORTHOPAEDICS" },
    { label: "DERMATOLOGY" },
    { label: "RADIOLOGY" },
    { label: "PSYCHIATRY" },
    { label: "PATHOLOGY" },
    { label: "CARDIOLOGY" },
    { label: "GASTROENTEROLOGY" },
    { label: "UROLOGY" },
  ];

  return (
    <>
      <Box sx={{ display: 'flex', flexDirection: 'column' }}>
        {/* Banner Section */}
        <Box
          sx={{
            backgroundImage: `url(${BannerImage})`,
            backgroundSize: 'contain',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
            height: { xs: '100px', sm: '250px', md: '300px' },
            width: '100%',
          }}
        >
          <Box sx={{ height: '10px', backgroundColor: '#B07E2C' }} />
        </Box>

        {/* Services Section */}
        <Container>
          <Grid
            container
            justifyContent="center"
            alignItems="center"
            mt={8}
            sx={{ color: "#171859" }}
          >
            <Grid item display="flex" alignItems="center">
              <img src={Ologo} alt="o-logo" width="40px" height="40px" />
              <Typography id="text-30-600-15-Poppins">UR</Typography>
              <Typography id="text-30-600-15-Poppins" color="#B07E2C" ml={1}>
                SERVICES
              </Typography>
            </Grid>
          </Grid>

          <Grid container spacing={4} mt={4} justifyContent="center">
            {/* Cardiology */}
            <Grid item xs={12} sm={6} md={4}>
              <Box display="flex" alignItems="center" justifyContent="center">
                <img src={cardio} alt="Cardiology" width="40px" height="40px" />
                <Typography id="text-20-600-15-Poppins" ml={1}>Cardiology</Typography>
              </Box>
              <Typography id="text-15-Poppins" mt={2} textAlign="justify">
                The cardiology department specializes in diagnosing, treating, and preventing heart-related diseases and conditions. It offers various services, including cardiac evaluations and treatment of coronary artery disease, arrhythmias, heart failure, and hypertension.
              </Typography>
            </Grid>

            {/* Urology */}
            <Grid item xs={12} sm={6} md={4}>
              <Box display="flex" alignItems="center" justifyContent="center">
                <img src={urology} alt="Urology" width="40px" height="40px" />
                <Typography id="text-20-600-15-Poppins" ml={1}>Urology</Typography>
              </Box>
              <Typography id="text-15-Poppins" mt={2} textAlign="justify">
                The Urology Department specializes in diagnosing and treating conditions related to the urinary tract and male reproductive system. It offers comprehensive services for both men and women, addressing issues such as urinary incontinence, kidney stones, urinary tract infections, and prostate problems.
              </Typography>
            </Grid>

            {/* Pulmonology */}
            <Grid item xs={12} sm={6} md={4}>
              <Box display="flex" alignItems="center" justifyContent="center">
                <img src={generalmedi} alt="Pulmonology" width="40px" height="40px" />
                <Typography id="text-20-600-15-Poppins" ml={1}>Pulmonology</Typography>
              </Box>
              <Typography id="text-15-Poppins" mt={2} textAlign="justify">
                The pulmonology department specializes in diagnosing and treating diseases related to the respiratory system, including the lungs and airways.
              </Typography>
            </Grid>
          </Grid>
        </Container>

        {/* Appointment Section */}
        <Box
          sx={{
            backgroundImage: `url(${AppointmentImage})`,
            backgroundSize: 'contain',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
            height: { xs: '80px', sm: '250px', md: '300px' },
            width: '100%',
          }}
        >
          {/* <Box component="form" sx={{ width: { xs: '30%', sm: '60%', md: '30%' } }}>
            <TextField
              fullWidth
              select
              id="text-15-Poppins"
              label="Select Department"
              defaultValue="Select Department"
              sx={{ backgroundColor: '#fff' }}
            >
              {Department.map((option) => (
                <option key={option.label} value={option.label}>
                  {option.label}
                </option>
              ))}
            </TextField>
          </Box> */}
        </Box>

        <Box sx={{ height: '10px', backgroundColor: '#B07E2C' }} />
      </Box>
    </>
  );
}

export default Homepage;
